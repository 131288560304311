import { getLegalDocument, LegalDocumentTypes } from '@/services/api'
import { logger } from '@/utils/logger'
import assert from 'assert'

export const openDocument = (pdfDoc: any, docName: LegalDocumentTypes | string) => {
    assert(pdfDoc, `pdfDoc of type ${docName} appears to be falsey, did it fail to generate or is it empty?`)

    let fileURL: string
    if (typeof pdfDoc === 'string') {
        fileURL = pdfDoc
    } else {
        const file = new Blob([pdfDoc], { type: 'application/pdf' })
        fileURL = URL.createObjectURL(file)
    }

    logger.log(`Attempting to open ${docName} in new tab`)
    const link = document.createElement('a')
    // create a blobURI pointing to our Blob
    link.href = fileURL
    link.download = docName
    link.target = '_blank'

    // some browser needs the anchor to be in the doc
    document.body.append(link)
    link.click()
    logger.log(`Clicked ${fileURL} to open ${docName} in new tab`)
    link.remove()

    // in case the Blob uses a lot of memory
    setTimeout(() => {
        logger.log(`Revoked ${docName} @ ${fileURL} to save memory`)
        URL.revokeObjectURL(fileURL)
    }, 5000)
}

export const getDocumentForApplicantAndOpen = async (docType: LegalDocumentTypes) => {
    const response = await getLegalDocument(docType)
    openDocument(response.data, docType)
}
