<template>
    <button
        :class="buttonClasses"
        :type="type"
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="submitting || disabled"
    >
        <img
            v-show="imageSrc"
            class="me-1"
            width="16"
            :src="getImageSrc"
            alt=""
        >
        <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
        />
        <span v-else><slot /></span>
    </button>
</template>

<script>
    export default {
        name: 'BaseButton',
        inheritAttrs: false,
        props: {
            type: {
                type: String,
                default: 'submit',
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            showIcon: {
                type: Boolean,
                default: true,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            getImageSrc() {
                if (!this.imageSrc) {
                    return null
                }

                return require(`../../assets/images/${this.imageSrc}`)
            },
        },
    }
</script>
