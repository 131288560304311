<template>
    <onboarding-layout
        :error-text="errorText"
        :loading="loading"
        :loading-title="loadingTitle"
    >
        <div
            class="d-grid"
            v-if="!hasAttemptedPersona"
            data-testid="scheduling-confirmation-persona-verification-section"
        >
            <h5
                class="fw-light"
                data-testid="scheduling-confirmation-text-persona-section"
                v-html="$t('pages.origination.personaVerification.text', { dateTime: formattedTimeSlot })"
            />
            <base-button
                :submitting="isLoadingEmbeddedPersona"
                button-classes="btn btn-primary mt-3"
                @click="launchPersona"
            >
                Verify Identity
            </base-button>
        </div>
        <div
            v-else
            data-testid="scheduling-confirmation-general-instruction-section"
        >
            <div class="text-center">
                <img
                    src="@/assets/images/origination/Calendar.svg"
                    alt="Appointment Confirmed"
                >
            </div>
            <div class="mt-6 text-center">
                <h5
                    class="fw-light mb-3"
                    data-testid="scheduling-confirmation-text"
                >
                    Great! Everything is ready for your appointment on <b>{{ formattedTimeSlot }}</b>
                </h5>
            </div>
            <div class="card border-0 bg-light py-3">
                <p class="section-header align-self-center fw-bold row d-block mb-3">
                    Before your session
                </p>
                <div class="d-flex flex-row align-items-center mx-3">
                    <img
                        src="@/assets/images/global/1.svg"
                        alt="1"
                        height="24px"
                        width="24px"
                        class="col-1 p-0 mb-2"
                    >
                    <p class="ms-1 d-block mb-0">
                        <a
                            href="#"
                            @click="getDocument(LegalDocumentTypes.accountAgreement)"
                            class="text-underline"
                        >View your account agreement</a> that you will review with the notary.
                    </p>
                </div>
                <div class="d-flex flex-row align-items-center mx-3 mt-3 mb-0">
                    <img
                        src="@/assets/images/global/2.svg"
                        alt="2"
                        height="24px"
                        width="24px"
                        class="col-1 p-0"
                    >
                    <p class="ms-1 d-block mb-0">
                        Bring your ID to the session.
                    </p>
                </div>
            </div>
        </div>
        <p
            class="text-muted text-center mt-3"
            data-testid="scheduling-confirmation-reschedule-text"
        >
            We have also sent a text and email session reminder with the steps above. If you need to reschedule,
            <span
                style="cursor: pointer"
                @click="reschedule"
            > <u>click here</u> </span>.
        </p>
        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.creditLimit"
            />
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import DateTime from 'luxon/src/datetime'
    import { getIsAppointmentAlreadyScheduled } from '@/services/schedulingApi'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OnboardingLayout from '@/layouts/Onboarding'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import { getNextRoute } from '@/flow/flowController'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import { LegalDocumentTypes } from '@/services/api'
    import { SchedulingThanksReasons } from '@/utils/thanksPageHelpers'
    import BaseButton from '@/components/base/BaseButton'
    import Persona from 'persona'

    export default {
        components: {
            OnboardingLayout,
            'offer-info-card': OfferInfoCard,
            'help-list-item': HelpListItem,
            BaseButton,
        },
        mixins: [computeCreditOfferFromStorageMixin],
        data: function () {
            return {
                LegalDocumentTypes,
                loading: true,
                loadingTitle: 'Loading...',
                errorText: '',
                confirmedTimeSlot: null,
                isLoadingEmbeddedPersona: false,
                personaInquiryId: null,
                personaInquirySessionToken: null,
                personaClient: null,
                hasAttemptedPersona: false,
                personaLink: null,
                applicantTimezone: 'America/Los_Angeles',
            }
        },
        computed: {
            formattedTimeSlot: function () {
                // E.g. "Friday, May 6 at 7:30 AM (PDT)"
                return DateTime.fromISO(this.confirmedTimeSlot).setZone(this.applicantTimezone).toFormat("cccc, LLLL d 'at' h:mm a (ZZZZ)")
            },
        },
        mounted: async function () {
            await this.main()
        },
        beforeDestroy() {
            logger.info('destroy persona client')
            this.personaClient?.destroy()
        },
        methods: {
            main: async function () {
                try {
                    this.loading = true
                    const confirmedTimeSlotResponse = await getIsAppointmentAlreadyScheduled()
                    const confirmedTimeSlotResponsePayload = confirmedTimeSlotResponse.data.payload

                    const sessionAlreadyInProgress = confirmedTimeSlotResponsePayload.isSessionImmediate
                    if (sessionAlreadyInProgress) {
                        // skip to kba
                        logger.info('Session is immediate (using backend API call), redirecting back into flow')
                        return await this.$router.push(getNextRoute(this.$router))
                    }

                    await this.parsePayload(confirmedTimeSlotResponsePayload)

                    // Staying on this page
                    logger.info('Session is not immediate, rendering page.')

                    this.$logEvent('view_scheduling_confirmation', { confirmedTimeSlot: this.confirmedTimeSlot, hasAttemptedPersona: this.hasAttemptedPersona })
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.loading = false
                }
            },
            parsePayload: async function (appointmentPayload) {
                this.confirmedTimeSlot = appointmentPayload.confirmedTimeSlot
                const applicantTimezoneData = appointmentPayload.applicantTimezoneData || {}
                if (!applicantTimezoneData.timeZone) {
                    this.applicantTimezone = 'America/Los_Angeles'
                } else {
                    this.applicantTimezone = applicantTimezoneData.timeZone
                }
                this.hasAttemptedPersona = appointmentPayload.hasAttemptedPersona
                if (this.hasAttemptedPersona) {
                    // if the Persona verification is completed, reset and return
                    this.personaLink = null
                    this.personaInquiryId = null
                    this.personaInquirySessionToken = null
                    this.personaClient?.destroy()
                    return
                }
                if (this.personaInquiryId === appointmentPayload.personaInquiryId) {
                    // if personaInquiryId stays the same, no need to update, return early
                    return
                }
                this.personaLink = appointmentPayload.personaLink
                this.personaInquiryId = appointmentPayload.personaInquiryId
                this.personaInquirySessionToken = appointmentPayload.personaInquirySessionToken
                logger.info(`get personaInquiryId ${this.personaInquiryId}, personaInquirySessionToken ${this.personaInquirySessionToken}`)
                this.personaClient?.destroy()
                if (this.personaInquiryId && this.personaInquirySessionToken) {
                    this.personaClient = new Persona.Client({
                        inquiryId: this.personaInquiryId,
                        sessionToken: this.personaInquirySessionToken,
                        environment: 'production',
                        onComplete: async ({ inquiryId, status }) => {
                            this.isLoadingEmbeddedPersona = false
                            logger.info(`onComplete Persona embedded flow for inquiry ${inquiryId} with status ${status}`)
                            this.$logEvent('event_persona_verification_embedded_complete', { inquiryId })
                            await this.main()
                        },
                        onCancel: async ({ inquiryId }) => {
                            this.isLoadingEmbeddedPersona = false
                            logger.info(`onCancel Persona embedded flow for inquiryId ${inquiryId}`)
                            this.$logEvent('event_persona_verification_embedded_cancel', { inquiryId })
                        },
                        onError: (error) => {
                            this.isLoadingEmbeddedPersona = false
                            logger.error(`onError Persona embedded flow for inquiryId ${this.personaInquiryId}: ${error.status} ${error.code} ${error.message}`)
                            this.$logEvent('event_persona_verification_embedded_error', { inquiryId: this.personaInquiryId, error: `${error.status} ${error.code} ${error.message}` })
                        },
                    })
                }
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdf document`, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = 'Loading...'
            },
            reschedule: async function () {
                return await this.$router.push({
                    path: getNextRoute(this.$router),
                    query: {
                        reason: SchedulingThanksReasons.tooEarlyToAppointment,
                        startTime: this.confirmedTimeSlot,
                    },
                })
            },
            launchPersona: async function () {
                if (!this.personaClient) {
                    this.$logEvent('click_persona_verification_link')
                    window.open(this.personaLink, '_blank')
                    return
                }
                this.isLoadingEmbeddedPersona = true
                this.$logEvent('click_persona_verification_embedded')
                this.personaClient.open()
            },
        },
    }
</script>
